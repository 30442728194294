import React from 'react';

const ClockSVG = ({ time }) => {
  const [hours, minutes] = time.split(':').map(Number);
  const hourAngle = (hours % 12) * 30 + (minutes / 60) * 30;
  const minuteAngle = minutes * 6;

  const width = 180;
  const height = width;
  const radius = width * 0.325;
  const cx = width/2;
  const cy = height/2;

  const calculateCoordinates = (degree) => {
    const radian = degree * (Math.PI / 180);
    const x = cx + radius * Math.cos(radian);
    const y = cy + radius * Math.sin(radian);
    return { x, y };
  };

  /* I need to find out why i have to adjust these by -90
   * degrees below
   */
  const labels = [
    { hour: '1', degree: 30 },
    { hour: '2', degree: 60 },
    { hour: '3', degree: 90 },
    { hour: '4', degree: 120 },
    { hour: '5', degree: 150 },
    { hour: '6', degree: 180 },
    { hour: '7', degree: 210 },
    { hour: '8', degree: 240 },
    { hour: '9', degree: 270 },
    { hour: '10', degree: 300 },
    { hour: '11', degree: 330 },
    { hour: '12', degree: 360 }
  ];

  return (
    <svg width={cx} height={cy} viewBox={`0 0 ${height} ${width}`} className='clock-problem'>
      <circle cx={cx} cy={cy} r={width*0.4} stroke="black" strokeWidth="5" fill="none" />
      <line
        x1={cx}
        y1={cy}
        x2={cx}
        y2={cy * 0.7}
        stroke="black"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="mitre"
        transform={`rotate(${hourAngle} ${cx} ${cy})`}
      />
      <line
        x1={cx}
        y1={cy}
        x2={cx}
        y2={cy * 0.5}
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="mitre"
        transform={`rotate(${minuteAngle} ${cx} ${cy})`}
      />
      {labels.map(({ hour, degree }) => {
        const { x, y } = calculateCoordinates(degree-90);
        return <text key={hour} x={x} y={y + 5} font-size="smaller" textAnchor="middle">{hour}</text>;
      })}
    </svg>
  );
};

export default ClockSVG;
