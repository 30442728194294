import './App.css';
import Footer from './components/Footer.jsx';
import ProblemTypeButtons from './components/ProblemTypeButtons.jsx';
import Settings from './components/Settings.jsx';
import GenerateButtons from './components/GenerateButtons.jsx';
import Hero from './components/Hero.jsx';
import ProblemsGrid from './components/ProblemsGrid.jsx';
import Affirmation from './components/Affirmation.jsx';
import Header from './components/Header.jsx';
import React, { useState, useEffect } from 'react';
import {default as mathC} from './Constants.js';

const generateRandomTime = () => {
  const hours = Math.floor(Math.random() * 12);
  const minutes = Math.floor(Math.random() * 12) * 5;
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

const App = () => {

  const [factor, setFactor] = useState(1);
  const [limit, setLimit] = useState(20)

  const problemTypesAndNames = {
    oneDigitAddition: { text: "1-Digit Addition", digits: 1, type: mathC.ADDITION_TYPE },
    twoDigitAddition: { text: "2-Digit Addition", digits: 2, type: mathC.ADDITION_TYPE },
    threeDigitAddition: { text: "3-Digit Addition", digits: 3, type: mathC.ADDITION_TYPE },
    oneDigitSubtraction: { text: "1-Digit Subtraction", digits: 1, type: mathC.SUBTRACTION_TYPE },
    twoDigitSubtraction: { text: "2-Digit Subtraction", digits: 2, type: mathC.SUBTRACTION_TYPE },
    threeDigitSubtraction: { text: "3-Digit Subtraction", digits: 3, type: mathC.SUBTRACTION_TYPE },
    oneDigitMultiplication: { text: "1-Digit Multiplication", digits: 1, type: mathC.MULTIPLICATION_TYPE },
    twoDigitMultiplication: { text: "2-Digit Multiplication", digits: 2, type: mathC.MULTIPLICATION_TYPE },
    threeDigitMultiplication: { text: "3-Digit Multiplication", digits: 3, type: mathC.MULTIPLICATION_TYPE },
    timesTable: { text: "Times Table Problem", factor: factor, limit: limit },
    clockProblem: { text: "Clock Problems", digits: 0, type: mathC.CLOCK_TYPE }
  };

  const [problemTypes, setProblemTypes] = useState({
    oneDigitAddition: true,
    twoDigitAddition: false,
    threeDigitAddition: false,
    oneDigitSubtraction: false,
    twoDigitSubtraction: false,
    threeDigitSubtraction: false,
    oneDigitMultiplication: false,
    twoDigitMultiplication: false,
    threeDigitMultiplication: false,
    timesTable: false,
    clockProblem: false
  });

  const [numberOfProblems, setNumberOfProblems] = useState(20);
  const [problems, setProblems] = useState([]);

  useEffect(() => {
    applyPrintClass();
  }, [numberOfProblems]);

  useEffect(() => {
    applyPrintClass();
  }, []);

  const applyPrintClass = () => {
    const root = document.documentElement;
    root.classList.remove('print-28', 'print-50', 'print-100');
    if (numberOfProblems === mathC.PRINT_LENGTH_20) {
      root.classList.add('print-28');
    } else if (numberOfProblems === mathC.PRINT_LENGTH_50) {
      root.classList.add('print-50');
    } else if (numberOfProblems === mathC.PRINT_LENGTH_100) {
      root.classList.add('print-100');
    }
  };

  const handleProblemTypeChange = (type) => {
    setProblemTypes({
      ...problemTypes,
      [type]: !problemTypes[type],
    });
  };

  const generateProblems = () => {
    const newProblems = [];
    const types = Object.keys(problemTypes).filter(type => problemTypes[type]);

    if (types.length < 1) {
      return
    }

    while (newProblems.length < numberOfProblems) {
      const randomType = types[Math.floor(Math.random() * types.length)];
      const newType = problemTypesAndNames[randomType];

      switch (randomType) {
        case 'oneDigitAddition':
          newProblems.push(generateProblem(1, newType.type));
          break;
        case 'twoDigitAddition':
          newProblems.push(generateProblem(2, newType.type));
          break;
        case 'threeDigitAddition':
          newProblems.push(generateProblem(3, newType.type));
          break;
        case 'oneDigitSubtraction':
          newProblems.push(generateProblem(1, newType.type));
          break;
        case 'twoDigitSubtraction':
          newProblems.push(generateProblem(2, newType.type));
          break;
        case 'threeDigitSubtraction':
          newProblems.push(generateProblem(3, newType.type));
          break;
        case 'oneDigitMultiplication':
          newProblems.push(generateProblem(1, newType.type));
          break;
        case 'twoDigitMultiplication':
          newProblems.push(generateProblem(2, newType.type));
          break;
        case 'threeDigitMultiplication':
          newProblems.push(generateProblem(3, newType.type));
          break;
        case 'timesTable':
          newProblems.push(generateTimesTableProblem(factor, limit));
          break;
        case 'clockProblem':
          const time = generateRandomTime()
          newProblems.push(generateClockProblem(time, newType.type))
          break;
        default:
          break;
      }
    }

    setProblems(newProblems);
  };

  // from MSDN Math.Random()
  function getRandomIntInclusive(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
  }

  const generateTimesTableProblem = (factor, limit) => {
    let num1 = getRandomIntInclusive(0, limit);
    let num2 = factor;

    return {
      type: mathC.MULTIPLICATION_TYPE,
      num1,
      num2,
    };
  }

  const generateProblem = (digits, type) => {
    let num1 = Math.floor(Math.random() * Math.pow(10, digits));
    let num2 = Math.floor(Math.random() * limit);

    if (num1 < num2) {
      [num1, num2] = [num2, num1];
    }

    return {
      type: type,
      num1,
      num2,
    };
  }

  const generateClockProblem = (time, type) => {
    return {
      type: type,
      time,
    };
  }

  const printProblems = () => {
    window.print();
  };

  const buttonClasses = (type) => `
    px-4 py-2 m-2 rounded 
    ${problemTypes[type] ? 'bg-blue-200' : 'bg-gray-200'} 
    hover:bg-blue-100 
    transition-colors
  `;

  return (
    <div className={`flex flex-col min-h-screen p-4 print-${numberOfProblems}`}>
      <Hero />
      <div>
        <ProblemTypeButtons problemTypes={problemTypes} problemTypesAndNames={problemTypesAndNames} handleProblemTypeChange={handleProblemTypeChange} buttonClasses={buttonClasses} />
        <Settings numberOfProblems={numberOfProblems} setNumberOfProblems={setNumberOfProblems} factor={factor} setFactor={setFactor} limit={limit} setLimit={setLimit} />
        <GenerateButtons generateProblems={generateProblems} printProblems={printProblems} />
      </div>
      <div>
        <Header />
        <Affirmation />
        <ProblemsGrid problems={problems} />
        <Footer />
      </div>
    </div>
  );
};

export default App;