import React from 'react';

const Hero = () => {
    return (
        <div className='w-full h-[40vh] no-print'>
            <img className='top-0 left-0 w-full h-[40vh] object-cover no-print' src="noita-digital-MdmcpX2ofRk-unsplash.jpg" alt="/"></img>
            <div className='absolute top-0 w-full h-[40vh] flex flex-col justify-center text-white'>
                <div className='md:left-[10%] max-w-[1100px] m-auto absolute p-4'>
                    <h1 className='font-bold text-5xl md:text-7xl drop-shadow-2xl'>Math Problem Generator</h1>
                    <p className='max-w-[600px] drop-shadow-2xl py-4 text-xl'>
                        Use this to generate math worksheets for your kids. Select at least one or more problem types 
                        from those available below. We're always adding more, so check back often. Suggestions welcome!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Hero;
