import React from 'react';
import ClockSVG from './ClockSVG';
import {default as mathC} from '../Constants';

const ProblemsGrid = ({ problems }) => {

  const attr = problems.length === mathC.PRINT_LENGTH_50 ? "grid-cols-5" : "grid-cols-4";

  // use for debugging
  problems.filter((element, index, self) => {
    // filtering condition
    if (element.type === mathC.CLOCK_TYPE) {
      return true;
    }
    return false;
  })
    .map((element, index, self) => {
      // return value from map method
      //console.log("debugging element: ", element.time)
      return true;
    })


  return (
    <div className={`flex-grow grid ${attr} gap-4 problems-grid text-xl`}>
      {problems.map((problem, index) => (
        <div key={index} className="problem flex flex-col content-start">
          {(problem.type === mathC.CLOCK_TYPE) ? <ClockSVG time={problem.time} /> : <div className="num1">{problem.num1}</div>}
          <div className="operator-num2">
            {(problem.type === mathC.CLOCK_TYPE) ? <span></span> : <span>{problem.type}</span>}
            <span>{problem.num2}</span>
          </div>
          <div className="line-container">
            {(problem.type === mathC.CLOCK_TYPE) ? <div className="clock-line"></div> : <div className="line"></div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProblemsGrid;
