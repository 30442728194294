import React from 'react';

const GenerateButtons = ({ generateProblems, printProblems }) => {
  return (
    <div className="flex-grow mb-4 generate-buttons">
      <button onClick={generateProblems} className="bg-green-500 text-white hover:bg-green-600 transition-colors">Generate Problems</button>
      <button onClick={printProblems} className="bg-sky-500 text-white hover:bg-blue-600 transition-colors ml-2 print-button">Print</button>
    </div>
  );
};

export default GenerateButtons;
