import React from 'react';
import { FaXTwitter, FaArrowUpRightFromSquare } from 'react-icons/fa6'

const Footer = () => {
    return (
        <div className='print-footer text-sm footer-div w-full mt-1 bg-gray-900 text-gray-300 py-8 px-4'>
            <div className='print-footer grid grid-cols-3 max-w-[1400px] px-4 py-8 mx-auto text-gray-500'>
                <div className='pl-4 text-left'>
                    <p>&copy; 2024 Math Problem Generator. All rights reserved.</p>
                </div>
                <div className='flex justify-center px-4'>
                    <a href="https://x.com/mymathsheets" className='flex no-print' rel="noreferrer" target="_blank">
                        <FaXTwitter size={16} /> <FaArrowUpRightFromSquare size={12} />
                    </a>
                </div>
                <div className='pr-4 text-right'>
                    <a className="hover:underline decoration-sky-500 hover:text-white" href="https://mymathsheets.com">mymathsheets.com</a>
                </div>
            </div>
        </div>
    )
}

export default Footer;