import React from 'react';

const ProblemTypeButtons = ({ problemTypes, problemTypesAndNames, handleProblemTypeChange, buttonClasses }) => {

  const renderButtons = Object.keys(problemTypes).map(function (key, index) {
    return( <button key={index} onClick={() => handleProblemTypeChange(key)} className={buttonClasses(key)}>
        {problemTypesAndNames[key].text}
      </button>)

  });
  
  return (
    <div className="mb-4 generate-buttons grid grid-cols-2 md:grid-cols-3">
      {renderButtons}
    </div>
  );
};

export default ProblemTypeButtons;
