import React from 'react';
import {default as mathC} from '../Constants';

const Settings = ({ numberOfProblems, setNumberOfProblems, factor, setFactor, limit, setLimit }) => {
  return (
    <div className="mb-4 settings flex-grow grid grid-cols-3 gap-3">
      <div>
        <label>Number of problems per page:</label>
        <select value={numberOfProblems} onChange={(e) => setNumberOfProblems(parseInt(e.target.value))} className="ml-2 p-2 rounded bg-gray-200">
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div>
        <label>Times Table Factor:</label>
        <select value={factor} onChange={(e) => setFactor(parseInt(e.target.value))} className="ml-2 p-2 rounded bg-gray-200">
          {[...Array(limit + 1).keys()].map(i => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Largest value to add or multiply:</label>
        <select value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} className="ml-2 p-2 rounded bg-gray-200">
          {[...Array(21).keys()].map(i => (
            <option key={i} value={i}>{i}</option>
          ))}
          <option key={22} value={50}>{50}</option>
          <option key={23} value={100}>{100}</option>
          <option key={24} value={200}>{200}</option>
          <option key={25} value={300}>{300}</option>
        </select>
      </div>
    </div>
  );
};

export default Settings;
